@import 'settings';
@import 'foundation';
@import 'motion-ui';
@include motion-ui-transitions;
@include motion-ui-animations;
@include foundation-everything;


#main-header, .top-bar {
	
	background-color: $red;

	ul {
	
		background-color: $red;
	
		li {

			a {
				color: #fff;
				transition: color 0.5s ease;

				&:hover {
					color: #d9d9d9;
				}

			}

		}

	}


}

#header-logo {

	max-height: 3rem;
	padding-left: 2rem;


}

#front-slider {
	margin-bottom: 2rem;
	background-color: #d9d9d9;
	border-bottom: 1px solid #999;

}
.orbit-wrapper {
	 -moz-box-shadow:    inset 0 0 10px #000000;
   -webkit-box-shadow: inset 0 0 10px #000000;
   box-shadow:         inset 0 0 10px #000000;
}

.widget-title {

}
.widget-text {

}
.widget-thumb {
	float: left;
	margin-right: 1rem;
	max-width: 10rem;
	border: 3px solid #d9d9d9;
}

#support-ribbon {
	margin-top: 4rem;
	background-color: $red-dark;
	padding: 2rem;
	color: #fff;
}
 
#main-footer {
	font-size: .9rem;
	background-color: #666;
	color: #fff;
	padding:2rem;
	width: 100%;

	&.fixed-footer {
		position: fixed;
		bottom: 0;
		width: 100%;
	}

	a { color: #d9d9d9; }

	#footer-menu {
		li {
			a {
				&:last-of-type {
					padding-right: 0;
				}
			}
		}
	}
}

#main-content {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

#header-img {
	background-image: url('../images/food-sunset-love-field.jpg');
	background-size: cover;
	background-position: bottom center;
	height: 200px;

	h1 { color: #fff; 
		margin-top: 5rem;
	}
}

.roboto { font-family: $roboto; }
.open-sans { font-family: $open-sans; }

#form-builder-body, #page-builder-body {
	background-color: #d9d9d9;
	min-height: 500px;
	padding: 1rem;
}

.page-controls {
	font-size: .8rem;
	border-top: 1px solid #999;
	margin-top: 2rem;
	margin-bottom: 1rem;
	padding-top: 1rem;
}

.event-listing {
	padding: 1rem;
	text-align: center;
}

.event-thumb {
	border: 4px solid #d9d9d9;
	margin-bottom: 1rem;
	max-width: 250px;
}

#global-success {
	background-color: RGBA(75, 105, 42, .7);
	color: #fff;
	padding: 1rem .25rem .25rem;
	position: fixed;
	bottom: 0;
	width: 100%;
}

.page-title {
	text-shadow: 2px 2px #666;
}

.faq-item {
	border-bottom: 1px solid #cacaca;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	
	.faq-question {
		font-weight: bold;
	}
	.faq-meta {
		text-align: right;
		color: #999;
		font-size: .9rem;
	}
}

p.help-text {
	margin-top: 1rem;
	color: #333;
}

.button {
	&.green {
		background-color: green;
		color: #fff;
	}
}

.req {
	color: red;
}